import type { EditorSDK, RouterRef } from '@wix/platform-editor-sdk';
import type { PageRoleId } from '@wix/pricing-plans-router-utils';

const TOKEN = '';
const ROUTER_PREFIX = 'pricing-plans';

// Allows to identify page by it's purpose/role instead of requiring to use page IDs which are unique per site.
const PackagePickerPageRole = 'PricingPlans' as const;
const CheckoutPageRole = 'Checkout' as const;
const ThankYouPageRole = 'ThankYou' as const;
const PaywallPageRole = 'Paywall' as const;

export async function installRouter(sdk: EditorSDK) {
  console.log('looking for router with pricing-plans prefix ...');
  let routerRef = await sdk.document.routers.getByPrefix(TOKEN, { prefix: ROUTER_PREFIX });
  if (!routerRef) {
    console.log('adding router with pricing-plans prefix ...');
    const pages = await sdk.document.pages.getApplicationPages(TOKEN);
    routerRef = await sdk.document.routers.add(TOKEN, { prefix: ROUTER_PREFIX, config: { pages } });
    console.log('added!');
    console.log('connecting pages to router ...');
    await connectAllPagesToRouter(sdk, routerRef);
    console.log('connected!');
  } else {
    console.log('fetching router data ...');
    const routerData = await sdk.document.routers.get(TOKEN, { routerRef });
    console.log('fetched!');
    if (routerData.prefix !== ROUTER_PREFIX) {
      console.log('updating existing router ...');
      await sdk.document.routers.update(TOKEN, { routerRef, prefix: ROUTER_PREFIX });
      console.log('updated!');
    } else {
      console.log('router is up to date.');
    }
    const installedPageRoles = routerData.pages.flatMap((page) => page.pageRoles);
    if (!installedPageRoles.includes(CheckoutPageRole)) {
      await connectCheckoutPageToRouter(sdk, routerRef);
    }
    if (!installedPageRoles.includes(ThankYouPageRole)) {
      await connectThankYouPageToRouter(sdk, routerRef);
    }
    if (!installedPageRoles.includes(PaywallPageRole)) {
      await connectPaywallPageToRouter(sdk, routerRef);
    }
    if (!installedPageRoles.includes(PackagePickerPageRole)) {
      await connectPackagePickerPageToRouter(sdk, routerRef);
    }
  }
}

export async function uninstallRouter(sdk: EditorSDK) {
  console.log('looking for router with pricing-plans prefix ...');
  const routerRef = await sdk.document.routers.getByPrefix(TOKEN, { prefix: ROUTER_PREFIX });
  if (routerRef) {
    console.log('removing router ...');
    await sdk.document.routers.remove(TOKEN, { routerRef });
    console.log('removed!');
  } else {
    console.log('not found!');
  }
}

async function connectAllPagesToRouter(sdk: EditorSDK, routerRef: RouterRef) {
  await connectPaywallPageToRouter(sdk, routerRef);
  await connectCheckoutPageToRouter(sdk, routerRef);
  await connectThankYouPageToRouter(sdk, routerRef);
  await connectPackagePickerPageToRouter(sdk, routerRef);
}

const connectPaywallPageToRouter = async (sdk: EditorSDK, routerRef: RouterRef) =>
  connectPageToRouter('Paywall', 'paywall', sdk, routerRef);

const connectCheckoutPageToRouter = async (sdk: EditorSDK, routerRef: RouterRef) =>
  connectPageToRouter('Checkout', 'checkout', sdk, routerRef);

const connectThankYouPageToRouter = async (sdk: EditorSDK, routerRef: RouterRef) =>
  connectPageToRouter('Thank You', 'thank_you', sdk, routerRef);

const connectPackagePickerPageToRouter = async (sdk: EditorSDK, routerRef: RouterRef) =>
  connectPageToRouter('membership_plan_picker_tpa', 'pricing_plans', sdk, routerRef);

async function connectPageToRouter(tpaPageId: string, pageRole: PageRoleId, sdk: EditorSDK, routerRef: RouterRef) {
  console.log(`connecting ${tpaPageId} page to router ...`);
  await sdk.document.routers.pages.connect(TOKEN, {
    routerRef,
    pageRef: await sdk.document.tpa.getPageRefByTPAPageId(TOKEN, { tpaPageId }),
    pageRoles: [pageRole],
  });
  console.log('connected!');
}
